import Link from 'next/link';
import {
  ProductFilled,
  MaginWandFilled,
  PolyMailerFilled,
  NotesPaperFilled,
  WindmillFilled,
  PackagingFilled,
  RetouchStickerFilled,
  PrintTshirtFilled,
  FreelancerFilled,
  WarehouseStorageFilled,
  EshopFilled,
  ShoppingVoucherMailFilled,
  AdvertisingMegaphoneFilled,
  AnalyticsGraphBarFilled,
  WebsiteBuildAiFilled,
} from 'src/components/Common/Icons';
import './index.less';

interface IProps {
  closeActiveMenu: () => void;
  isMobile?: boolean;
}

const EcommerceSuite = ({ closeActiveMenu, isMobile }: IProps) => {
  return (
    <div className="e-commerce-suite-menu-container" onClick={closeActiveMenu}>
      <div className="e-commerce-suite-menu-content">
        <div className="e-commerce-suite-menus-container">
          <div className="e-commerce-suite-menus-group">
            <div className="e-commerce-suite-menus-group-body">
              <div className="e-commerce-suite-menus sourcing">
                <ul>
                  <li>
                    <Link href={'/platform/sourcing-and-production/products'}>
                      <ProductFilled />
                      <span className="title">Create custom products</span>
                      <span className="description">Source from vetted factories</span>
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/print-on-demand'}>
                      <PrintTshirtFilled />
                      <span className="title">Print on demand</span>
                      <span className="description">Made to order merch</span>
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/sourcing-and-production/packaging'}>
                      <PackagingFilled />
                      <span className="title">Create custom packaging</span>
                      <span className="description">Source from vetted factories</span>
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/sourcing-and-production/freelancers'}>
                      <FreelancerFilled />
                      <span className="title">Find creative services</span>
                      <span className="description">Work with vetted freelancers</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="e-commerce-suite-menus-group">
            <div className="e-commerce-suite-menus">
              <ul>
                <li>
                  <Link href={'/platform/design-studio'}>
                    <MaginWandFilled />
                    <span className="title">Design Studio</span>
                    <span className="description">Visualize ideas with AI</span>
                  </Link>
                </li>
              </ul>
              <h4 className="design-quick-packaging-h4">Get packaging fast</h4>
              <ul className="design-quick-packaging-ul">
                <li>
                  <Link href={'/platform/sourcing-and-production/packaging/polymailers'}>
                    <PolyMailerFilled />
                    <span className="description">Polymailers</span>
                  </Link>
                </li>
                <li>
                  <Link href={'/platform/sourcing-and-production/packaging/notecards'}>
                    <NotesPaperFilled />
                    <span className="description">Notecards</span>
                  </Link>
                </li>
                <li>
                  <Link href={'/platform/sourcing-and-production/packaging/tissue-paper'}>
                    <WindmillFilled />
                    <span className="description">Tissue paper</span>
                  </Link>
                </li>
                <li>
                  <Link href={'/platform/sourcing-and-production/packaging/shipping-boxes'}>
                    <PackagingFilled />
                    <span className="description">Shipping boxes</span>
                  </Link>
                </li>
                <li>
                  <Link href={'/platform/sourcing-and-production/packaging/stickers'}>
                    <RetouchStickerFilled />
                    <span className="description">Stickers</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="e-commerce-suite-menus-group">
            <div className="e-commerce-suite-menus-group-body">
              <div className="e-commerce-suite-menus">
                <ul>
                  <li className="fulfillment">
                    <Link href={'/platform/logistics-and-fulfillment'}>
                      <WarehouseStorageFilled />
                      <span className="title">Fulfillment</span>
                      <span className="description">Store and ship products</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="e-commerce-suite-menus-group">
            <div className="e-commerce-suite-menus-group-body">
              <div className="e-commerce-suite-menus grow">
                <ul>
                  <li>
                    <Link href={'/platform/retail-connect'}>
                      <EshopFilled />
                      <span className="title">
                        Retail Connect<span className="pill new">New</span>
                      </span>
                      <span className="description">Sell your products in local retailers</span>
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/print-on-demand'}>
                      <WebsiteBuildAiFilled />
                      <span className="title">Pietra Stores</span>
                      <span className="description">Set up an online storefront</span>
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/email-capture'}>
                      <ShoppingVoucherMailFilled />
                      <span className="title">Email List Builder</span>
                      <span className="description">Build an email list automatically</span>
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/affiliate-marketing'}>
                      <AdvertisingMegaphoneFilled />
                      <span className="title">Affiliate Management</span>
                      <span className="description">Manage an affiliate program</span>
                    </Link>
                  </li>
                  <li>
                    <Link href={'/platform/analytics'}>
                      <AnalyticsGraphBarFilled />
                      <span className="title">Analytics</span>
                      <span className="description">Leverage powerful analytics</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EcommerceSuite;
