import { useToggle, useWindowScroll, useWindowSize } from 'react-use';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Affix } from 'antd';
import DesktopHeader from './Desktop';
import { useConfigProvider } from '@/lib/context';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { DARK_ROUTER_PATHS } from '@/shared/constants/nav-constants';
import './index.less';
import { logCommonView } from '@/lib/service';
import { EventSource } from '@/shared/constants/event';
import dynamic from 'next/dynamic';

const MobileHeader = dynamic(() => import('@/components/Common/Layout/Header/Mobile'), { ssr: false });

interface IProps {
  isLarge?: boolean;
  showIntercom: () => void;
  hideIntercom: () => void;
  isFullLength?: boolean;
}

export default function Header({ isLarge = false, isFullLength = false, showIntercom, hideIntercom }: IProps) {
  const { isMobile } = useConfigProvider();
  const { width } = useWindowSize();
  const router = useRouter();
  const { y } = useWindowScroll();

  const [openLeftMenuDrawer, openLeftMenuDrawerFn] = useToggle(false);
  const [mobileNavTheme, setMobileNavTheme] = useState<'light' | 'dark'>('light');
  const [showMobileHeader, setShowMobileHeader] = useState(false);

  const showMobileNav = useMemo(() => isMobile || (width ?? 1281) < 1024, [width, isMobile]);

  const goToPreviewFlow = useCallback(() => {
    logCommonView(EventSource.BOOK_A_DEMO, EventSource.BOOK_A_DEMO, isMobile);
    router.push('/existing-brands/signup');
  }, [isMobile, router]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const htmlElement = window.document.querySelector('html');
    if (!htmlElement) return;

    const setTheme = (theme: string) => htmlElement.setAttribute('data-theme', theme);

    const theme = DARK_ROUTER_PATHS.some((path) => router.asPath === path) ? 'dark' : 'light';

    if (showMobileNav) {
      setMobileNavTheme(theme);
    } else {
      setTheme(theme);
    }
    setShowMobileHeader(showMobileNav);
  }, [isMobile, router.asPath, showMobileNav]);

  useEffect(() => {
    if (openLeftMenuDrawer) {
      hideIntercom();
    } else {
      showIntercom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openLeftMenuDrawer]);

  const mobileHeader = useMemo(
    () => (
      <MobileHeader
        openLeftMenuDrawer={openLeftMenuDrawer}
        openLeftMenuDrawerFn={openLeftMenuDrawerFn}
        goToPreviewFlow={goToPreviewFlow}
        breakpoint={width ?? 1281}
      />
    ),
    [goToPreviewFlow, openLeftMenuDrawer, openLeftMenuDrawerFn, width],
  );

  const defaultHeader = useMemo(
    () => <DesktopHeader isLarge={isLarge} isFullLength={isFullLength} goToPreviewFlow={goToPreviewFlow} />,
    [goToPreviewFlow, isFullLength, isLarge],
  );

  const headerContainer = useMemo(
    () => (showMobileHeader ? mobileHeader : defaultHeader),
    [showMobileHeader, mobileHeader, defaultHeader],
  );

  return (
    <div
      className={classNames('pietra-header', mobileNavTheme, { mobile: showMobileNav, 'pietra-sticky-header': y > 0 })}
    >
      <Affix>{headerContainer}</Affix>
    </div>
  );
}
