import HeaderMenu from '../../HeaderMenu';
import Image from 'next/image';
import Link from 'next/link';
import classNames from 'classnames';
import { Dropdown } from 'antd';
import { EventSource } from '@/shared/constants/event';
import { NavMenuType } from '@/types/layout';
import { logCommonView, supplierPortalLink, webToolsLink } from '@/lib/service';
import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useWindowScroll } from 'react-use';
import './index.less';

interface IProps {
  isLarge?: boolean;
  isFullLength?: boolean;
  goToPreviewFlow: () => void;
}

export default function DesktopHeader({ isLarge = false, isFullLength = false, goToPreviewFlow }: IProps) {
  const { pathname } = useRouter();
  const { y } = useWindowScroll();

  const [activeMenu, setActiveMenu] = useState<NavMenuType>(null);

  const loginDropdown = [
    {
      key: '1',
      label: (
        <Link href={webToolsLink()} target="_blank">
          Sign in as a Brand
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link href={supplierPortalLink()} target="_blank">
          Sign in as a Supplier
        </Link>
      ),
    },
  ];

  const menu = useMemo(() => {
    return (
      <HeaderMenu
        activeMenu={activeMenu}
        closeActiveMenu={() => {
          setActiveMenu(null);
        }}
      />
    );
  }, [activeMenu]);

  return (
    <div
      className={classNames('pietra-header-container', {
        'sticky-header': y > 0,
        large: isLarge,
        'full-length': isFullLength,
      })}
    >
      {menu}
      <div className="header-left">
        <Link href={'/'} className="logo">
          <Image
            width={80}
            height={21}
            src="/images/header/small-logo.png"
            alt="Pietra"
            title="Pietra"
            loading="eager"
          />
        </Link>

        <span
          className={classNames('custom-menu-trigger', { active: activeMenu === NavMenuType.E_COMMERCE_SUITE })}
          onMouseEnter={(e) => {
            e.preventDefault();
            setActiveMenu(NavMenuType.E_COMMERCE_SUITE);
          }}
        >
          <span>What Pietra offers</span>
          <div className="dropdown-arrow" />
        </span>
        <span
          className={classNames('custom-menu-trigger', { active: activeMenu === NavMenuType.SOLUTIONS })}
          onMouseEnter={(e) => {
            e.preventDefault();
            setActiveMenu(NavMenuType.SOLUTIONS);
          }}
        >
          <span>Who is Pietra for</span>
          <div className="dropdown-arrow" />
        </span>
        <span
          className={classNames('custom-menu-trigger', { active: activeMenu === NavMenuType.RESOURCES })}
          onMouseEnter={(e) => {
            e.preventDefault();
            setActiveMenu(NavMenuType.RESOURCES);
          }}
        >
          <span>Resources</span>
          <div className="dropdown-arrow" />
        </span>
        <Link
          key="pricing"
          className={classNames('pricing', {
            active: pathname.startsWith('/plans-and-pricing'),
          })}
          href="/plans-and-pricing"
          onMouseEnter={(e) => {
            e.preventDefault();
            setActiveMenu(null);
          }}
          onClick={(event) => {
            logCommonView(EventSource.PRICING, 'CLICK');
          }}
        >
          Pricing
        </Link>
      </div>
      <div className="header-right">
        <Dropdown
          getPopupContainer={(triggerNode) => document.querySelector('#loginRef') ?? document.body}
          menu={{
            items: loginDropdown,
          }}
        >
          <a className="active" onClick={(e) => e.preventDefault()} id="loginRef">
            <span>Sign in</span>
            <div className="dropdown-arrow" />
          </a>
        </Dropdown>

        <div className="nav-button demo" onClick={goToPreviewFlow} key="preview-pietra">
          <span
            className="preview-pietra"
            onMouseEnter={(e) => {
              e.preventDefault();
              setActiveMenu(null);
            }}
          >
            Book a demo
          </span>
        </div>

        <Link className="button-warp" href="/sell-on-pietra/register">
          Start for free
        </Link>
      </div>
    </div>
  );
}
